import { apiRoute, authHeader, handleResponse } from '../helpers'
import moment from 'moment'

export const labelledImagesService = {
  getLabelledImages,
  getAILabelledImages,
  download,
  getBatches,
  getLabelledImagesSingle,
  getAILabelledImagesSingle
}

function getLabelledImages (projectId) {
  const requestOptions = {
    method: 'GET',
    headers: { ...authHeader(), 'Content-Type': 'application/json' }
  }

  return fetch(
    `${apiRoute()}/api/v1/admin/training/labels/${projectId}`,
    requestOptions
  ).then(handleResponse)
}
function getLabelledImagesSingle (projectId, imgId) {
  const requestOptions = {
    method: 'GET',
    headers: { ...authHeader(), 'Content-Type': 'application/json' }
  }

  return fetch(
    `${apiRoute()}/api/v1/admin/training/labels_single/${projectId}/${imgId}`,
    requestOptions
  ).then(handleResponse)
}

function getAILabelledImages (projectId) {
  const requestOptions = {
    method: 'GET',
    headers: { ...authHeader(), 'Content-Type': 'application/json' }
  }

  return fetch(
    `${apiRoute()}/api/v1/admin/training/labels/${projectId}/ai`,
    requestOptions
  ).then(handleResponse)
}
function getAILabelledImagesSingle (projectId, imgId) {
  const requestOptions = {
    method: 'GET',
    headers: { ...authHeader(), 'Content-Type': 'application/json' }
  }

  return fetch(
    `${apiRoute()}/api/v1/admin/training/labels_single/${projectId}/ai/${imgId}`,
    requestOptions
  ).then(handleResponse)
}

function download (_payload) {
  // console.log(_payload)
  // https://new-labeller.radify.ai/api/v1/admin/training/images/download/yolo?projectId=657a9a36e30f30001a1acba0&start_date=2023-01-01&end_date=2023-12-31
  const requestOptions = {
    method: 'GET',
    headers: { ...authHeader(), 'Content-Type': 'application/json' }
    // body: _payload
  }
  var eDate = moment(_payload.dateEnd).format('YYYY-MM-DD')
  var sDate = moment(_payload.dateStart).format('YYYY-MM-DD')

  return fetch(
    `${apiRoute()}/api/v1/admin/training/images/download/yolo?projectId=${_payload.projectID}&start_date=${sDate}&end_date=${eDate}&pathology=${_payload.pathology}`,
    requestOptions
  ).then(handleResponse)
}

function getBatches () {
  const requestOptions = {
    method: 'GET',
    headers: { ...authHeader(), 'Content-Type': 'application/json' }
  }

  return fetch(
    `${apiRoute()}/api/v1/admin/training/images/batch`,
    requestOptions
  ).then(handleResponse)
}
