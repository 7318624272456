import { projectService } from '../services'

const state = {
  loading: false,
  projects: [],
  selectedProjectId: null,
  projectImages: [],
  loadingProjectImages: false,
  allImages: [],
  loadingAllImages: false,
  loadingRejects: false,
  rejects: []
}

const actions = {
  // problem
  async createProject ({ dispatch, commit }, project) {
    commit('setLoading', true)
    try {
      const response = await projectService.create(project)
      commit('setLoading', false)
      dispatch('alert/success', 'Successfully Create a New Project', {
        root: true
      })
      dispatch('getUserProjects')
      commit('setCreatedProject', project)
      return response
    } catch (error) {
      commit('setLoading', false)
      dispatch('alert/error', 'Failed to create project', { root: true })
      return error
    }
  },
  async getUserProjects ({ dispatch, commit }) {
    commit('setLoading', true)
    try {
      const projectId = JSON.parse(localStorage.getItem('user')).id
      const projects = await projectService.getUserProjects(projectId)
      commit('setLoading', false)
      commit('setProjects', projects)
      return projects
    } catch (error) {
      commit('setLoading', false)
      dispatch('alert/error', 'Failed to get projects', { root: true })
      return error
    }
  },

  async updateProject ({ dispatch, commit }, project) {
    commit('setLoading', true)

    try {
      const response = await projectService.update(project)
      commit('setLoading', false)
      dispatch('alert/success', 'Successfully Updated Project', { root: true })
      dispatch('getUserProjects')
      return response
    } catch (error) {
      commit('setLoading', false)
      dispatch('alert/error', 'Failed to update project', { root: true })
      return error
    }
  },

  selectProject ({ commit }, projectId) {
    commit('setSelectedProjectId', projectId)
  },

  async getProjectImages2 ({ commit, dispatch }, projectId) {
    try {
      // // console.log('id', projectId)
      commit('clearProjectImages')
      commit('setProjectImagesLoading', true)
      const images = await projectService.getProjectImages(projectId)
      commit('setProjectImages', images.images)
      commit('setProjectImagesLoading', false)
    } catch (error) {
      commit('setProjectImagesLoading', false)
      dispatch('alert/error', 'Failed to get project images', { root: true })
      return error
    }
  },
  async getProjectImages2Single ({ commit, dispatch }, payload) {
    const projectId = payload.projectId
    const imgId = payload.imageID
    try {
      // // console.log('id', projectId)
      commit('clearProjectImages')
      commit('setProjectImagesLoading', true)
      const images = await projectService.getProjectImages(projectId, imgId)
      commit('setProjectImages', images.images)
      commit('setProjectImagesLoading', false)
    } catch (error) {
      commit('setProjectImagesLoading', false)
      dispatch('alert/error', 'Failed to get project images', { root: true })
      return error
    }
  },

  async submitProjectImages ({ commit, dispatch }, params) {
    try {
      commit('setLoading', true)
      await projectService.submitProjectImages(params.projectId, params.payload)
      commit('setLoading', false)
      return null
    } catch (error) {
      return error
    }
  },

  async getAllImages2 ({ commit, dispatch }, projectId) {
    commit('clearAllImages')
    commit('setAllImagesLoading', true)
    try {
      const allImages = await projectService.getAllImages(projectId)
      commit('setAllImages', allImages.images)
      commit('setAllImagesLoading', false)
    } catch (error) {
      dispatch('alert/error', 'Failed to images', { root: true })
      commit('setAllImagesLoading', false)
      throw new Error(error)
    }
  },

  // problem
  async getRejections2 ({ commit, dispatch }, projectId) {
    commit('clearRejects')
    commit('setLoadingRejection', true)
    try {
      const allRejects = await projectService.getRejections(projectId)
      // // console.log('rej image')
      commit('setRejection', allRejects)
    } catch (error) {
      dispatch('alert/error', 'Failed to load rejected images', { root: true })
      commit('setLoadingRejection', false)
      throw new Error(error)
    }
  },

  async assignImageToProject ({ commit, dispatch }, projectId, imageIds) {
    commit('setLoading', true)
    try {
      const assignedImage = await projectService.assignImageToProject({
        projectId,
        imageIds
      })
      commit('setLoading', false)
      await dispatch('getAllImages2', projectId.projectId)
      await dispatch('getProjectImages2', projectId.projectId)
      dispatch('alert/success', 'Image/s assigned to project', {
        root: true
      })

      return assignedImage
    } catch (error) {
      dispatch('alert/error', 'Failed to assign image to project', {
        root: true
      })
      console.error('Failed to assign image to project', error)
      throw new Error(error)
    }
  },

  // problem
  async removeImageFromProject ({ commit, dispatch }, payload) {
    try {
      // // console.log('store remove', payload)
      const removedImages = await projectService.removeImageFromProject(
        payload.removalIds,
        payload.p_id,
        payload.reason,
        payload.phase
      )
      commit('alert/success', 'Image(s) removed successfully', { root: true })
      await dispatch('getRejections2', payload.p_id)
      return removedImages
    } catch (error) {
      console.error(error)
      commit('alert/error', 'Image(s) removal unsuccessful', { root: true })
      throw new Error(error)
    }
  }
}

const mutations = {
  clearProjectImages (state) {
    state.projectImages = []
  },
  clearAllImages (state) {
    state.allImages = []
  },
  clearRejects (state) {
    state.rejects = []
  },
  setLoading (state, loading) {
    state.loading = loading
  },
  setLoadingRejection (state, loading) {
    state.loadingRejects = loading
  },
  setRejection (state, data) {
    state.rejects = data
    state.loadingRejects = false
  },
  setProjects (state, projects) {
    state.projects = projects
  },
  setCreatedProject (state, project) {
    state.projects.push(project)
  },
  setSelectedProjectId (state, projectId) {
    state.selectedProjectId = projectId
  },
  setProjectImages (state, images) {
    state.projectImages = images
  },
  setAllImages (state, allImages) {
    state.allImages = allImages
  },
  setProjectImagesLoading (state, loading) {
    state.loadingProjectImages = loading
  },
  setAllImagesLoading (state, loading) {
    state.loadingAllImages = loading
  }
}

export const projectSubmission = {
  namespaced: true,
  state,
  actions,
  mutations
}
