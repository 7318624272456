import { authService } from '../services'
import { router } from '../helpers'

const user = JSON.parse(localStorage.getItem('user'))
const state = user
  ? { status: { loggedIn: true }, user }
  : { status: {}, user: null }

const actions = {
  login ({ dispatch, commit }, user) {
    commit('loginRequest', user)
    authService.login(user)
      .then(
        user => {
          commit('loginSuccess', user)
          router.push('/')
          dispatch('alert/clear', null, { root: true })
          dispatch('alert/success', 'Successfully logged in.', { root: true })
          dispatch('projectSubmission/getUserProjects', null, { root: true })
        },
        error => {
          commit('loginFailure', error)
          dispatch('alert/error', error, { root: true })
        }
      )
  },
  change ({ dispatch, commit }, passwords) {
    commit('changeRequest')

    authService.change(passwords)
      .then(
        () => {
          commit('changeSuccess')
          dispatch('alert/clear', null, { root: true })
          dispatch('alert/success', 'Successfully changed password.', { root: true })
        },
        error => {
          commit('changeFailure', error)
          dispatch('alert/error', error, { root: true })
        }
      )
  },
  logout ({ dispatch, commit }) {
    authService.logout()
    commit('logout')
    dispatch('alert/clear', null, { root: true })
  },
  register ({ dispatch, commit }, user) {
    commit('registerRequest', user)

    authService.register(user)
      .then(
        user => {
          commit('registerSuccess', user)
          router.push('/')
          dispatch('alert/success', 'Registration submitted for processing. You will contacted within 2 (two) business days.', { root: true })
        },
        error => {
          commit('registerFailure', error)
          dispatch('alert/error', error, { root: true })
        }
      )
  },
  increaseImagesToday ({ commit }) {
    commit('increase')
  }
}

const mutations = {
  loginRequest (state, user) {
    state.status = { loggingIn: true }
    state.user = user
  },
  loginSuccess (state, user) {
    state.status = { loggedIn: true }
    state.user = user
  },
  loginFailure (state) {
    state.status = {}
    state.user = null
  },
  changeRequest (state) {
    state.status = { changing: true }
  },
  changeSuccess (state) {
    state.status = { changed: true }
  },
  changeFailure (state) {
    state.status = { changed: false }
  },
  logout (state) {
    state.status = {}
    state.user = null
  },
  registerRequest (state) {
    state.status = { registering: true }
  },
  registerSuccess (state) {
    state.status = {}
  },
  registerFailure (state) {
    state.status = {}
  },
  increase (state) {
    state.user.imagesToday += 1
    localStorage.setItem('user', JSON.stringify(state.user))
  }
}

export const auth = {
  namespaced: true,
  state,
  actions,
  mutations
}
