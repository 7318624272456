import { apiRoute } from '../helpers/api-route'
const state = {
  annotations: [],
  label: [],
  set: [],
  image: [],
  phase: {},
  isOpen: false,
  loading: false,
  windowOptions: []
}

const actions = {
  clear ({ commit }) {
    commit('clear')
  },
  toggleVisablity ({ commit }, val) {
    commit('toggleV', val)
  },
  getAnnotationsMap ({ commit }, payload) {
    commit('toggleLoading')
    var image = payload.image
    var phase = payload.phaseData
    var images = payload.labeledSubmissions
    var members = payload.members
    var imagesAi = payload.aiImages
    commit('setPahse', phase)
    var selectedUser = []
    // // // console.log('Final', image)
    var selectedImage = []
    selectedImage.push(image.url)
    // var rejectPayload = {
    //   projectId: this.projectId,
    //   imageID: image.id,
    //   phase: this.selected
    // }
    var img = new Image()
    img.src = image.url
    img.onload = (e) => {
      // // // console.log(img.height)
      selectedImage.push([img.width, img.height])
      selectedImage.push(image.details)
      // // // console.log(image.details)
      // // // console.log(image)
      let user = null
      if (phase.phase !== 1) {
        const _tempImg = images // labeled submissions
        user = getUserFromImage(_tempImg, image.id, false, members)
        // // // console.log(image)
      }
      var userAi
      var _tempAi = []
      var _tempImgAi = []
      console.log('image')
      console.log(image)
      if (image.analyzed) {
        console.log('img', image)
        if (image.details && image.details.image_set && image.details.image_set.name) {
          _tempAi = JSON.parse(JSON.stringify(imagesAi.find(ig => ig.image.image_set.name === image.details.image_set.name && ig.image.image_set.sequence === image.details.image_set.sequence)))
          _tempImgAi = _tempAi.otherImages
          var _curAi = _tempAi
          _curAi.otherImages = null
          console.log('tempai0', _tempImgAi)
          _tempImgAi = _tempImgAi.concat(_curAi)
        } else {
          _tempImgAi = imagesAi
        }
        // // // console.log(image)
        // // // console.log(_tempImgAi)
        // console.log('tempai1', _tempImgAi)
        userAi = getUserFromImage(_tempImgAi, image.id, true, members)
        selectedUser = userAi.concat(user)
      } else {
        selectedUser = user
      }

      var annotations = []
      var windows = []
      if (selectedImage[2] && selectedImage[2].otherImages && selectedImage[2].otherImages.length > 0) {
        selectedImage[2].imgName = selectedImage[2].image_set.name
        windows.push(selectedImage[2].image_set.window)
        var _others = selectedImage[2].otherImages
        _others.forEach(setImg => {
          // var _selectedUser = []
          var _selectedImage = []
          let _user = null
          var imgUrl = apiRoute() + '/image/' + (phase.phase === 1 ? setImg._id : setImg.image._id)
          // // // console.log(imgUrl)
          _selectedImage.push(imgUrl) //
          var _img = new Image()
          _img.src = imgUrl //
          // // // console.log('setImg.image.image.name', setImg.image.image.name)
          _selectedImage.push([img.width, img.height])
          _selectedImage.push(image.details)//
          if (phase.phase !== 1) {
            _user = getUserFromImage(_others, setImg.image._id, false, members)
          }

          var _userAi
          if (image.analyzed || ((phase.phase === 6 || phase.phase === 7) && imagesAi.find(ig => ig.image.image_set.name === image.details.image_set.name && ig.image.image_set.sequence === image.details.image_set.sequence))) {
            // // // console.log(_tempImgAi)
            console.log('tempai3', imagesAi.find(ig => ig.image.image_set.name === image.details.image_set.name && ig.image.image_set.sequence === image.details.image_set.sequence))
            console.log('tempai3', _tempImgAi.find(ig => ig.image.image_set.name === image.details.image_set.name && ig.image.image_set.sequence === image.details.image_set.sequence))
            _userAi = getUserFromImage(_tempImgAi, setImg.image._id, true, members)
            _user = _userAi.concat(_user)
          }
          // else {
          //   selectedUser = _user
          // }

          windows.push(phase.phase === 1 ? setImg.image_set.window : setImg.image.image_set.window)
          var details = {
            created: (phase.phase === 1 ? setImg.created_at : setImg.image.created_at),
            format: (phase.phase === 1 ? setImg.image.contentType : setImg.image.image.contentType),
            imgName: (phase.phase === 1 ? setImg.image_set.name : setImg.image.image_set.name),
            meta: (phase.phase === 1 ? setImg.metadata : setImg.image.metadata),
            iou: (phase.phase === 1 ? 0 : setImg.image.accuracy.filter(a => a.project === phase.projectId).slice(-1)[0].value),
            aiIov: phase.phase === 6 || phase.phase === 7 ? setImg.image.ai_accuracy.filter(a => a.project === phase.projectId).slice(-1)[0].value : null,
            image_set: (phase.phase === 1 ? (setImg.image_set && setImg.image_set.name ? setImg.image_set : null) : setImg.image.image_set && setImg.image.image_set.name ? setImg.image.image_set : null)
          }
          annotations.push({ image: _selectedImage, boxes: _user ? _user.boxes : [], labelled: _user ? _user.labelled : false, imgId: (phase.phase === 1 ? setImg._id : setImg.image._id), details: details })
        })
      }
      // console.log('1', typeof annotations)
      // console.log('2', annotations)
      // console.log('22', image)
      var firstRecord = {}
      // console.log('windows', [...new Set(windows)])
      if (image.details && image.details.image_set && image.details.image_set.name && annotations.length > 0) {
        firstRecord = { image: selectedImage, boxes: selectedUser ? selectedUser.boxes : [], labelled: selectedUser ? selectedUser.labelled : false, imgId: phase.imageID, details: selectedImage[2] }
        annotations = annotations.length > 0 ? [firstRecord, ...annotations] : annotations.push(firstRecord)
        // annotations = annotations.sort((ann1, ann2) => ann1.details.image_set.identifier.localeCompare(ann2.details.image_set.identifier))
      } else if (annotations.length === 0) {
        firstRecord = { image: selectedImage, boxes: selectedUser ? selectedUser.boxes : [], labelled: selectedUser ? selectedUser.labelled : false, imgId: phase.imageID, details: selectedImage[2] }
        annotations.push(firstRecord)
      }
      commit('setWindows', [...new Set(windows)])
      commit('setSet', annotations)
      commit('setAnnotations', selectedUser ? selectedUser.boxes : [])
      commit('setImage', selectedImage)
      commit('toggleLoading')
    }
  }
  // async getMembers ({ commit }, projectId) {
  //   commit('getAllMembersRequest')
  //   userService.getMembers(projectId).then(
  //     (members) => commit('getAllMembersSuccess', members),
  //     (error) => commit('getAllMembersFailure', error)
  //   )
  // }
}

const mutations = {
  clear (state, val) {
    state.isOpen = val
    state.annotations = []
    state.label = []
    state.set = []
    state.image = []
    state.phase = {}
    state.isOpen = false
    state.loading = false
    state.windowOptions = []
  },
  toggleV (state, val) {
    state.isOpen = val
  },
  toggleLoading (state) {
    state.loading = !state.loading
  },
  setPahse (state, phaseData) {
    state.phase = phaseData
  },
  setAnnotations (state, data) {
    state.annotations = data
  },
  setWindows (state, data) {
    state.windowOptions = data
  },
  setSet (state, data) {
    state.set = data
  },
  setImage (state, data) {
    state.image = data
  }
}

export const BottomSheet = {
  namespaced: true,
  state,
  actions,
  mutations
}

function getUserFromImage (imageData, imgId, aiFlag, members) {
  // // // console.log('img da', imageData)
  // // // console.log('img da', imgId)
  // // // console.log('img da', aiFlag)
  // // // console.log('img da', members)
  let _labelled = false
  if (!Array.isArray(imageData)) {
    return { boxes: [], labelled: _labelled }
  }
  // // // console.log('image data', imageData)
  // // // console.log('image dataai', aiFlag)
  var users = []
  // // // console.log(imageData)
  // // // console.log(imgId)
  //  _img.otherImages && _img.otherImages.length > 0 ? _img.otherImages.find(__img => __img.image._id === imgId) :
  const img = imageData.find(_img => (_img.image._id === imgId))
  // // console.log(img)
  // // console.log(aiFlag)
  var bbColours = ['#C77D3D', '#A4414A', '#7D1E5F', '#5C0F6B', '#3C0A6F', '#1E0A6E', '#0B0C63', '#0A1052', '#0C1441', '#13192F', '#1D1E1E', '#2A2213', '#3A2709', '#4B2C00', '#5D3100', '#6F3600', '#813B00', '#933F00', '#A54200', '#B74400']
  // console.log(img)
  img.pathologies.forEach(_p => {
    var _bounding = []
    var _colour = !aiFlag ? bbColours.shift() : '#15385b'
    var user = !aiFlag ? members.find(mem => mem._id === _p.created_by) : { _id: 'ai', personal: { fullname: 'AI' } } // members
    var indexes = Object.keys(_p).slice(0, -1)
    // // // console.log(indexes)
    /* We have diagnosis now to show that there're no pathologies
    if (indexes.length === 0) {
      _bounding.push('Normal')
    }
    /* */
    indexes.forEach(ind => {
      var pathName = !aiFlag ? ind + ': ' + _p[ind].name : _p[ind].name
      // console.log('bboxes: ', _p[ind])
      var coordinates = JSON.parse(JSON.stringify(_p[ind].coords))
      const polygon = _p[ind].freehandpoints ? JSON.parse(JSON.stringify(_p[ind].freehandpoints)) : null
      if (!aiFlag) {
        _labelled = true
        _bounding.push({ colour: _colour, coordinates: coordinates, polygon: polygon, pathology: pathName, showBoundingBox: true })
      } else {
        if (pathName === 'apchest' || pathName === 'heart') {
          coordinates.x = coordinates.x - (coordinates.w / 2)
          coordinates.y = coordinates.y + (coordinates.h / 2)
        } else {
          coordinates.x = coordinates.x - (coordinates.w / 2)
          coordinates.y = coordinates.y - (coordinates.h / 2)
        }

        _labelled = true
        _bounding.push({ colour: _colour, coordinates: coordinates, polygon: polygon, pathology: pathName, showBoundingBox: true, confidence: _p[ind].confidence })
      }
    })
    var _tags = []
    if (img.tags && img.tags.find(d => d.created_by === _p.created_by) && img.tags.find(d => d.created_by === _p.created_by)[0]) {
      var tempTags = img.tags.find(d => d.created_by === _p.created_by)
      var keyst = Object.keys(tempTags)
      keyst.forEach(_k => {
        if (_k !== 'created_by') {
          // console.log('_k ', _k)
          // console.log('_k ', sub[_k])
          _tags.push(tempTags[_k])
        }
      })
    }
    var _diagnoses = []
    if (img.diagnoses && img.diagnoses.find(d => d.created_by === _p.created_by) && img.diagnoses.find(d => d.created_by === _p.created_by)[0]) {
      var tempDias = img.diagnoses.find(d => d.created_by === _p.created_by)
      var keys = Object.keys(tempDias)
      keys.forEach(_k => {
        if (_k !== 'created_by') {
          // console.log('_k ', _k)
          // console.log('_k ', sub[_k])
          _diagnoses.push(tempDias[_k])
        }
      })
    }
    users.push({ boundingBoxes: _bounding, id: user._id, name: user.personal.fullname, showBoundingBoxes: true, tags: _tags, diagnoses: _diagnoses })
  })
  // // // console.log('bboxes', users)

  // // // console.log('img da', users)
  return { boxes: users, labelled: _labelled }
}
