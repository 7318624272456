import { labelledImagesService } from '../services'

const state = {
  aiImages: [],
  aiLoaded: false,
  aiLoading: false,
  labelledImages: [],
  loaded: false,
  loading: false,
  downloading: false,
  batchLoading: false,
  batches: []
}

const actions = {
  async fetchLabelledImages2 ({ commit }, projectId) {
    commit('clearLabelledImages')
    commit('setLoading', true)
    try {
      const response = await labelledImagesService.getLabelledImages(projectId)
      commit('setLoading', false)
      commit('setLabelledImages', response)
    } catch (error) {
      console.error('Error fetching labelled images:', error)
      commit('setLoading', false)
    }
  },
  // problem
  async fetchAILabelledImages ({ commit }, projectId) {
    // console.log('ai pul')
    commit('clearAILabelledImages')
    commit('setAILoading', true)
    try {
      const response = await labelledImagesService.getAILabelledImages(projectId)
      commit('setAILoading', false)
      commit('setAILabelledImages', response)
    } catch (error) {
      console.error('Error fetching labelled images:', error)
      commit('setAILoading', false)
    }
  },
  async fetchLabelledImages2Single ({ commit }, payload) {
    const projectId = payload.projectId
    const imgId = payload.imageID
    commit('clearLabelledImages')
    commit('setLoading', true)
    try {
      const response = await labelledImagesService.getLabelledImagesSingle(projectId, imgId)
      commit('setLoading', false)
      commit('setLabelledImages', response)
    } catch (error) {
      console.error('Error fetching labelled images:', error)
      commit('setLoading', false)
    }
  },
  // problem
  async fetchAILabelledImagesSingle ({ commit }, payload) {
    const projectId = payload.projectId
    const imgId = payload.imageID
    // console.log('ai pul')
    commit('clearAILabelledImages')
    commit('setAILoading', true)
    try {
      const response = await labelledImagesService.getAILabelledImagesSingle(projectId, imgId)
      commit('setAILoading', false)
      commit('setAILabelledImages', response)
    } catch (error) {
      console.error('Error fetching labelled images:', error)
      commit('setAILoading', false)
    }
  },
  async downloadLabels ({ commit }, payload) {
    commit('setDownloading', true)
    try {
      await labelledImagesService.download(payload)
      commit('setDownloading', false)
      // // // console.log('download', payload)
    } catch (error) {
      console.error('Error downloading labelled images:', error)
      commit('setDownloading', false)
    }
  },
  async getBatches ({ commit }) {
    commit('clearBatches')
    commit('gettingBatches', true)
    try {
      const response = await labelledImagesService.getBatches()
      commit('setBatches', response)
      commit('gettingBatches', false)
    } catch (error) {
      console.error('Error downloading labelled images:', error)
      commit('gettingBatches', false)
    }
  }
}

const mutations = {
  setLoading (state, loading) {
    state.loaded = !loading
    state.loading = loading
  },
  setAILoading (state, loading) {
    state.aiLoaded = !loading
    state.aiLoading = loading
  },
  setDownloading (state, status) {
    state.downloading = status
  },
  setLabelledImages (state, images) {
    state.labelledImages = images
  },
  clearLabelledImages (state) {
    state.labelledImages = []
  },
  setAILabelledImages (state, images) {
    state.aiImages = images
  },
  clearAILabelledImages (state) {
    state.aiImages = []
  },
  setBatches (state, batches) {
    state.batches = batches
  },
  gettingBatches (state, status) {
    state.batchLoading = status
  },
  clearBatches (state) {
    state.batches = []
  }
}

export const labelledImagesSubmission = {
  namespaced: true,
  state,
  actions,
  mutations
}
