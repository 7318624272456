import { apiRoute, authHeader, handleResponse } from '../helpers'
export const projectService = {
  create,
  update,
  getUserProjects,
  getProjectImages,
  submitProjectImages,
  getAllImages,
  assignImageToProject,
  removeImageFromProject,
  getRejections,
  getProjectImagesSingle
}

function create (project) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(project)
  }
  return fetch(
    `${apiRoute()}/api/v1/training/images/projects`,
    requestOptions
  ).then(handleResponse)
}
function update (project) {
  const requestOptions = {
    method: 'PUT',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(project)
  }
  // console.log('this is body: ', JSON.stringify(project))
  return fetch(
    `${apiRoute()}/api/v1/training/images/projects/${project._id}`,
    requestOptions
  ).then(handleResponse)
}

function getUserProjects (projectId) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  }
  return fetch(
    `${apiRoute()}/api/v1/training/images/projects/${projectId}`,
    requestOptions
  ).then(handleResponse)
}

function getProjectImages (projectId) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  }
  return fetch(
    `${apiRoute()}/api/v1/admin/training/${projectId}/images`,
    requestOptions
  ).then(handleResponse)
}
function getProjectImagesSingle (projectId, imgId) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  }
  return fetch(
    `${apiRoute()}/api/v1/admin/training/${projectId}/images_single/${imgId}`,
    requestOptions
  ).then(handleResponse)
}
function getRejections (projectId) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  }
  return fetch(
    `${apiRoute()}/api/v1/admin/training/${projectId}/rejections/count`,
    requestOptions
  ).then(handleResponse)
}

function submitProjectImages (projectId, payload) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(payload)
  }
  return fetch(
    `${apiRoute()}/api/v1/admin/training/${projectId}/images`,
    requestOptions
  ).then(handleResponse)
}

function getAllImages (projectId) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  }
  return fetch(
    `${apiRoute()}/api/v1/admin/training/all-images/${projectId}`,
    requestOptions
  ).then(handleResponse)
}

function assignImageToProject (object, imageIds) {
  // console.log('from the service', object.projectId)
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(object.projectId)
  }

  return fetch(
    `${apiRoute()}/api/v1/admin/training/assign-images/`,
    requestOptions
  ).then(handleResponse)
}

function removeImageFromProject (_imageIds, _projectId, _reason, _phase) {
  var payload = {
    projectId: _projectId,
    imageIds: _imageIds,
    reason: _reason,
    phase: _phase
  }
  // console.log(payload)
  const requestOptions = {
    method: 'DELETE',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(payload)
  }
  return fetch(
    `${apiRoute()}/api/v1/admin/training/remove-images/`,
    requestOptions
  ).then(handleResponse)
}
