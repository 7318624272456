import { userService } from '../services'

const state = {
  users: [],
  members: [],
  user: {},
  loading: false,
  error: null,
  membersNotInProject: []
}

const actions = {
  getAll ({ commit }) {
    commit('getAllRequest')
    userService.users().then(
      (users) => commit('getAllSuccess', users),
      (error) => commit('getAllFailure', error)
    )
  },
  async getMembers ({ commit }, projectId) {
    commit('getAllMembersRequest')
    userService.getMembers(projectId).then(
      (members) => commit('getAllMembersSuccess', members),
      (error) => commit('getAllMembersFailure', error)
    )
  },

  async getMembersNotInProject ({ commit }, projectId) {
    commit('getMembersNotInProjectRequest')
    try {
      const response = await userService.getMembersNotInProject(projectId)
      const membersNotInProject = response
      // // console.log(membersNotInProject)
      commit('getMembersNotInProjectSuccess', membersNotInProject)
    } catch (error) {
      commit('getMembersNotInProjectFailure', error)
    }
  },
  updateUser ({ dispatch, commit }, user) {
    commit('updateUserRequest')
    userService.updateUserProfile(user, user._id).then(
      () => {
        commit('updateUserSuccess')
        dispatch('alert/success', 'User Profile Updated', { root: true })
      },
      (error) => commit('updateUserFailure', error)
    )
  },

  async deleteUser ({ dispatch, commit }, { projectId, userId }) {
    commit('deleteUserRequest')
    try {
      await userService.deleteUser(projectId, userId)
      commit('deleteUserSuccess')
      dispatch('alert/success', 'User Removed from Project', { root: true })
    } catch (error) {
      commit('deleteUserFailure', error)
      dispatch('alert/error', error, { root: true })
    }
  },
  async addMember ({ commit, dispatch }, { projectId, membersWithRoles }) {
    commit('addMemberRequest')
    try {
      // console.log(membersWithRoles)
      await userService.addMember(projectId, membersWithRoles)
      commit('addMemberSuccess')
      dispatch('alert/success', 'Member(s) added successfully', { root: true })
    } catch (error) {
      commit('addMemberFailure', error)
      dispatch('alert/error', error, { root: true })
    }
  }
}

const mutations = {
  getAllRequest (state) {
    state.loading = true
    state.error = null
  },
  getAllSuccess (state, users) {
    state.loading = false
    state.users = users
  },
  getAllFailure (state, error) {
    state.loading = false
    state.error = error
  },
  getAllMembersRequest (state) {
    state.members = []
    state.loading = true
    state.error = null
  },
  getAllMembersSuccess (state, members) {
    state.loading = false
    state.members = members
  },
  getAllMembersFailure (state, error) {
    state.loading = false
    state.error = error
  },
  updateUserRequest (state) {
    state.loading = true
    state.error = null
  },
  updateUserSuccess (state) {
    state.loading = false
  },
  updateUserFailure (state, error) {
    state.loading = false
    state.error = error
  },

  deleteUserRequest (state) {
    state.loading = true
    state.error = null
  },
  deleteUserSuccess (state) {
    state.loading = false
  },
  deleteUserFailure (state, error) {
    state.loading = false
    state.error = error
  },
  addMemberRequest (state) {
    state.loading = true
    state.error = null
  },
  addMemberSuccess (state) {
    state.loading = false
  },
  addMemberFailure (state, error) {
    state.loading = false
    state.error = error
  },
  getMembersNotInProjectRequest (state) {
    state.loading = true
    state.error = null
  },
  getMembersNotInProjectSuccess (state, membersNotInProject) {
    state.loading = false
    state.membersNotInProject = membersNotInProject
  },
  getMembersNotInProjectFailure (state, error) {
    state.loading = false
    state.error = error
  }
}

export const usersSubmission = {
  namespaced: true,
  state,
  actions,
  mutations
}
