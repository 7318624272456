import Vue from 'vue'
import Vuex from 'vuex'

import { alert } from './alert.module'
import { auth } from './auth.module'
import { usersSubmission } from './user.module'
import { staffSubmission } from './staff.submission.module'
import { projectSubmission } from './projects.module'
import { labelledImagesSubmission } from './labels.module'
import { BottomSheet } from './bottomSheet.module'

Vue.use(Vuex)

export const store = new Vuex.Store({
  modules: {
    alert,
    auth,
    staffSubmission,
    projectSubmission,
    usersSubmission,
    labelledImagesSubmission,
    BottomSheet
  }
})
